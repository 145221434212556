export const environment = {
  "production": false,
  "console":true,
  "API_URL": "https://apides.apiacpos.com/api_bridge/",
  "numSerie": "WEBAN$A$l06$A$GULAR$A$AM3[jC)",
  "chClaveEmpresa": "FMCMP_Madrid",
  "chNombreProyecto": "VENTAONLINE",
  "chEntornoConexion": "DESARROLLO",
  "chOrigenConexion": "INTERNET",
  "chPasarelaEntorno": "DESARROLLO",
  "pasarelaRedsys": "DESARROLLO",
  "pasarelaPhoneSell": "PHONE_M_PRE",
  "pasarelaEntornoPhoneSell": "CONSULTA_PRE",
  "pasarelaPaypal": "PAYPAL_PRE",
  "URL_PDF": "http://grecpruebas.iacpos.com/grec-api/tickets/",
  "URLOK": "http://ticketsmadrid.fundacioncristinamasaveu.iacposdes.com/thankyou",
  "URLKO": "http://ticketsmadrid.fundacioncristinamasaveu.iacposdes.com/thankko",
  "URL": "http://ticketsmadrid.fundacioncristinamasaveu.iacposdes.com",
  "siteKey" : "6LfXS2IUAAAAADr2WUPQDzAnTEbSQzE1Jxh0Zi0a"
};
